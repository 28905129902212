import * as React from 'react';

function ListeningLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <title>Le logo qui montre: être à l'écoute</title>
      <path d="M330 165C330 74.019 255.981 0 165 0S0 74.019 0 165c0 41.702 7.756 83.163 15.256 123.258C22.505 327.009 30 367.08 30 406c0 58.448 47.103 106 105 106s105-47.552 105-106c0-7.449-.777-15.521-2.134-22.146-7.319-35.767 19.024-77.399 43.807-102.182C312.837 250.508 330 209.073 330 165zM208.476 389.869C209.43 394.533 210 400.563 210 406c0 41.906-33.645 76-75 76s-75-34.094-75-76c0-41.702-7.756-83.163-15.256-123.258C37.495 243.991 30 203.92 30 165 30 90.561 90.561 30 165 30s135 60.561 135 135c0 36.06-14.042 69.961-39.54 95.459-31.489 31.49-61.682 82.022-51.984 129.41z" />
      <path d="M184.617 61.797C118.696 49.724 60 100.154 60 165c0 36.108 6.904 72.8 14.23 111.733 2.353 12.5 4.786 25.425 6.969 38.303C85.62 341.09 107.694 360 133.688 360c25.27 0 47.207-17.984 52.162-42.762.037-4.611 18.099-53.027-19.431-98.806-18.918-23.076-46.381-36.854-75.991-38.304A276.404 276.404 0 0190 165c0-45.217 40.761-82.566 89.213-73.694 58.862 10.78 81.342 84.185 38.823 126.74l21.223 21.204c59.047-59.099 28.733-162.184-54.642-177.453zm-41.398 175.654c27.523 33.572 13.312 68.767 13.213 73.903C154.271 322.158 144.706 330 133.688 330c-11.311 0-20.946-8.404-22.91-19.981-2.229-13.144-4.688-26.204-7.081-38.921-3.926-20.864-7.73-41.081-10.329-60.659 19.444 2.103 37.286 11.686 49.851 27.012zM361 240h151v30H361zM369.273 388.406l13.416-26.832 119.989 59.995-13.416 26.831zM369.3 121.582l119.99-59.994 13.415 26.83-119.989 59.995z" />
    </svg>
  );
}

export default ListeningLogo;
