import * as React from 'react';

function AmbitiousLogo(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 511.949 511.949"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Le logo qui montre l'ambition</title>
      <path d="M436.188 151.807l73.901-73.901-55.829-22.077L432.184 0l-73.901 73.901v56.692l-13.91 13.91c-36.342-31.617-83.787-50.785-135.626-50.785C94.669 93.719 1.86 186.528 1.86 300.606c0 53.512 20.422 102.343 53.881 139.111L4.722 490.736l21.213 21.213L77.483 460.4c35.739 29.41 81.474 47.093 131.264 47.093 49.828 0 95.597-17.708 131.348-47.16l51.616 51.616 21.213-21.213-51.097-51.098c33.416-36.759 53.808-85.559 53.808-139.032 0-51.465-18.894-98.597-50.103-134.835l13.964-13.964zm-47.906-65.48l32.806-32.806 10.055 25.426 25.424 10.054-32.806 32.806h-35.479zm-2.648 214.279c0 97.536-79.351 176.887-176.887 176.887S31.86 398.143 31.86 300.606s79.351-176.888 176.887-176.888c43.567 0 83.497 15.841 114.357 42.054l-29.842 29.842c-23.139-18.663-52.542-29.855-84.515-29.855-74.354 0-134.846 60.492-134.846 134.847s60.492 134.846 134.846 134.846 134.846-60.491 134.846-134.846c0-31.597-10.929-60.687-29.198-83.699l29.86-29.86c25.81 30.749 41.379 70.367 41.379 113.559zm-72.041 0c0 57.813-47.034 104.846-104.846 104.846s-104.846-47.033-104.846-104.846S150.934 195.76 208.747 195.76c23.696 0 45.579 7.906 63.157 21.213l-29.516 29.517a63.348 63.348 0 00-33.64-9.633c-35.151 0-63.75 28.599-63.75 63.75s28.598 63.75 63.75 63.75 63.75-28.599 63.75-63.75a63.36 63.36 0 00-9.069-32.731l29.584-29.584c12.924 17.432 20.58 38.995 20.58 62.314zm-71.097 0c0 18.609-15.14 33.75-33.75 33.75s-33.75-15.141-33.75-33.75 15.14-33.75 33.75-33.75c3.968 0 7.776.694 11.316 1.958L198.877 290l21.213 21.213 20.822-20.822a33.627 33.627 0 011.584 10.215z" />
    </svg>
  );
}

export default AmbitiousLogo;
