import * as React from 'react';

function AndroidLogo(props) {
  return (
    <svg viewBox="0 0 66 79" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Le logo d'Android</title>
      <path d="M13.2 26.333l.003 31.087a5.124 5.124 0 005.138 5.122H19.8V74.2c0 2.646 2.333 4.799 4.983 4.799 2.66 0 4.917-2.153 4.917-4.802V62.542h6.6V74.2c0 2.646 2.333 4.799 4.986 4.799 2.66 0 4.914-2.153 4.914-4.802V62.542l1.465-.004a5.13 5.13 0 005.135-5.125v-31.08H13.2zM66 31.175c0-2.65-2.294-4.842-4.95-4.842-2.656-.003-4.95 2.196-4.95 4.846v20.099c0 2.653 2.29 4.68 4.95 4.68 2.657 0 4.95-2.024 4.95-4.68V31.175zm-56.1 0c0-2.65-2.294-4.842-4.95-4.842C2.293 26.33 0 28.53 0 31.18v20.099c0 2.653 2.29 4.68 4.95 4.68 2.656 0 4.95-2.024 4.95-4.68V31.175zM42.738 7.107L45.84.987C46.065.553 45.778 0 45.302 0a.611.611 0 00-.54.346l-3.133 6.181c-5.052-2.426-11.695-2.672-17.255 0L21.239.346A.604.604 0 0020.698 0c-.476 0-.763.553-.538.988l3.102 6.119C17.173 10.51 13.2 15.612 13.2 23.038h39.6c0-7.426-3.973-12.528-10.062-15.931zm-17.985 9.351c-.91 0-1.653-.737-1.653-1.645a1.65 1.65 0 013.3 0c0 .908-.736 1.645-1.647 1.645zm16.494 0a1.646 1.646 0 110-3.291c.91 0 1.653.744 1.653 1.646 0 .908-.742 1.645-1.653 1.645z" />
    </svg>
  );
}

export default AndroidLogo;
